<script setup lang="ts">
const props = defineProps<{
  name: string
  fill?: boolean
  box?: boolean
  noMargin?: boolean
  strokeWidth?: number | string
}>()

const isRtl = computed(() => props.name.includes('left') || props.name.includes('right'))

// const UILibraryImports = import.meta.glob('@~/assets/icons/**/**.svg', {
// 	as: 'raw',
// 	eager: false,
// })

const ProjectImports = import.meta.glob('@/assets/icons/**/**.svg', {
  as: 'raw',
  eager: false,
})

const iconsImport = getIconsImport()

const icon = ref(await getIcon())
watch(
  () => props.name,
  async () => (icon.value = await getIcon()),
  {
    immediate: true,
  },
)

//
async function getIcon() {
  let iconRaw = ''

  let importFunc = iconsImport.get(props.name)
  if (importFunc) {
    try {
      iconRaw = await importFunc()
    } catch {}
  }

  if (!iconRaw) {
    console.error(`[nuxt-icons] Icon '${props.name}' doesn't exist in 'assets/icons'`)
    return `missing icon (${props.name})`
  }

  return iconRaw
}

function getIconsImport() {
  const importsRegex = new RegExp(/assets\/icons\/(.*)\.svg$/)
  const importsMap = new Map<string, () => Promise<string>>()

  // for (const key in UILibraryImports) {
  // 	let match = importsRegex.exec(key)
  // 	if (!match || match.length < 1) continue

  // 	importsMap.set(match[1], UILibraryImports[key])
  // }

  for (const key in ProjectImports) {
    let match = importsRegex.exec(key)
    if (!match || match.length < 1) continue

    importsMap.set(match[1], ProjectImports[key])
  }

  return importsMap
}
</script>

<template>
  <span
    class="nuxt-icon"
    :class="{
      'nuxt-icon--fill': fill,
      'nuxt-icon--box': box,
      'nuxt-icon--no-margin': noMargin,
      /* 'nuxt-icon--rtl': $i18n?.locale === 'ar' && isRtl, */
      'nuxt-icon--custom-stroke-width': !!strokeWidth,
    }"
    :style="{ '--custom-stroke-width': strokeWidth }"
    v-html="icon"
  ></span>
</template>

<style lang="scss" scoped>
.nuxt-icon :deep(svg) {
  width: 1em;
  height: 1em;
  margin-bottom: 0.125em;
  vertical-align: middle;
}

.nuxt-icon--fill,
.nuxt-icon--fill * {
  fill: currentColor !important;
}

.nuxt-icon--box {
  display: flex;
  align-items: center;
  justify-content: center;

  :deep(svg) {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    vertical-align: unset;
  }
}

.nuxt-icon--no-margin :deep(svg) {
  margin-bottom: 0;
}

.nuxt-icon--rtl :deep(svg) {
  transform: rotateY(180deg);
}

.nuxt-icon--custom-stroke-width :deep(svg) {
  path {
    stroke-width: var(--custom-stroke-width);
  }
}
</style>
