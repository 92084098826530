<script setup lang="ts">
definePageMeta({
  layout: 'auth',
})

useHead({ title: 'Login' })

const supabase = useSupabaseClient()
const route = useRoute()

// local auth
const authForm = reactive({
  email: '',
  password: '',
})

async function authSubmit() {
  const { data, error } = await supabase.auth.signInWithPassword({
    email: authForm.email,
    password: authForm.password,
  })

  if (error) {
    console.error(error)
    return
  }

  navigateTo('/')
}
</script>

<template>
  <div
    class="container relative hidden h-dvh flex-col items-center justify-center min-[900px]:grid lg:max-w-none lg:grid-cols-2 lg:px-0 max-sm:flex max-sm:flex-col-reverse max-sm:px-0"
  >
    <div
      class="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex max-sm:flex max-sm:w-full max-sm:rounded-t-3xl"
    >
      <div
        class="absolute inset-0 flex items-center justify-center max-sm:rounded-t-3xl"
        :key="route.fullPath"
        :style="`background: url(/auth-bg.png); background-size: cover; background-position: center -200px;`"
      >
        <div class="max-w-[559px] -mt-[200px] hidden lg:block">
          <h1 class="text-5xl font-semibold mb-6">Ejaad works even better with your apps</h1>
          <p class="text-lg">
            Connect your account, and instantly search through all your files, folders, and
            documents with ease—everything is at your fingertips in seconds!
          </p>
        </div>
      </div>
    </div>
    <div class="lg:p-8 bg-white flex h-dvh">
      <div
        class="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[400px] max-sm:px-5 max-sm:space-y-4"
      >
        <div class="flex flex-col text-center">
          <img src="/logo.svg" alt="logo" class="h-[55.94px] max-sm:h-[27.9px]" />
          <h1 class="text-3xl font-semibold tracking-tight mt-11 max-sm:text-2xl max-sm:mt-[38px]">
            Log in to your account
          </h1>
          <p
            class="text-base text-muted-foreground mt-4 max-sm:text-sm max-sm:text-gray-700 max-sm:mt-2"
          >
            We'll create an account for you in your first login
          </p>
        </div>
        <UserAuthForm class="max-sm:w-[300px] max-sm:mx-auto max-sm:mt-4" />
        <p class="px-8 text-center text-sm text-muted-foreground mt-6">
          By clicking continue, you agree to our
          <a href="/terms" class="underline underline-offset-4 hover:text-primary">
            Terms of Service
          </a>
          and
          <a href="/privacy" class="underline underline-offset-4 hover:text-primary">
            Privacy Policy
          </a>
          .
        </p>

        
      </div>
    </div>
  </div>
</template>
