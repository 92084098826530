export default function useStreamPostFetch<T>(
  request: string,
  opts: any = { server: true, immediate: true },
) {
  let _opts = toValue(opts)
  if (_opts?.method !== 'post' && _opts?.method !== 'POST')
    throw new Error('useStreamPostFetch works only with POST requests.')

  const data = ref<string | null>(null)
  const status = ref<'idle' | 'success' | 'pending' | 'error' | 'streaming'>('idle')
  const error = ref(null)

  watchEffect(() => {
    _opts = toValue(opts)
  })

  async function execute() {
    data.value = null
    error.value = null
    status.value = 'pending'

    try {
      const response = await $fetch<ReadableStream>(request, {
        ..._opts,
        responseType: 'stream',
      })

      // Create a new ReadableStream from the response with TextDecoderStream to get the data as text
      const reader = response.pipeThrough(new TextDecoderStream()).getReader()

      // Read the chunk of data as we get it
      while (true) {
        const { value, done } = await reader.read()

        if (done) {
          status.value = 'success'

          break
        }

        if (!data.value) data.value = ''
        data.value += value
        status.value = 'streaming'
      }
    } catch (err) {
      console.error(err)
      status.value = 'error'
      error.value = err
    }
  }

  onMounted(async () => {
    if (_opts.immediate) {
      await execute()
    }
  })

  return {
    data,
    status,
    error,
    execute,
  }
}
