
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexbhTNAZvUkDMeta } from "/app/apps/web/pages/asset-preview_s_[assetID]/index.vue?macro=true";
import { default as _91id_939mzCGMI4kJMeta } from "/app/apps/web/pages/chat/[id].vue?macro=true";
import { default as index1MJspdZgNgMeta } from "/app/apps/web/pages/chat/index.vue?macro=true";
import { default as newkn5h0tOdjYMeta } from "/app/apps/web/pages/chat/new.vue?macro=true";
import { default as chatXiBMB8uVmYMeta } from "/app/apps/web/pages/chat.vue?macro=true";
import { default as index7lgcdDtzGEMeta } from "/app/apps/web/pages/employees/index.vue?macro=true";
import { default as connectlrwWmFOtTkMeta } from "/app/apps/web/pages/google/connect.vue?macro=true";
import { default as indexIiqKGJCATYMeta } from "/app/apps/web/pages/index.vue?macro=true";
import { default as integrations9QJIMIrkW5Meta } from "/app/apps/web/pages/integrations.vue?macro=true";
import { default as indexT3xEHBaZ63Meta } from "/app/apps/web/pages/login/confirm/index.vue?macro=true";
import { default as indexQJhoqCw595Meta } from "/app/apps/web/pages/login/index.vue?macro=true";
import { default as indexDQga8WyskIMeta } from "/app/apps/web/pages/manage/index.vue?macro=true";
import { default as index1KuNlqShLrMeta } from "/app/apps/web/pages/overview/index.vue?macro=true";
export default [
  {
    name: "asset-preview_s_assetID",
    path: "/asset-preview_s_:assetID()",
    component: () => import("/app/apps/web/pages/asset-preview_s_[assetID]/index.vue")
  },
  {
    name: chatXiBMB8uVmYMeta?.name,
    path: "/chat",
    component: () => import("/app/apps/web/pages/chat.vue"),
    children: [
  {
    name: "chat-id",
    path: ":id()",
    meta: _91id_939mzCGMI4kJMeta || {},
    component: () => import("/app/apps/web/pages/chat/[id].vue")
  },
  {
    name: "chat",
    path: "",
    meta: index1MJspdZgNgMeta || {},
    redirect: "/chat/new",
    component: () => import("/app/apps/web/pages/chat/index.vue")
  },
  {
    name: "chat-new",
    path: "new",
    meta: newkn5h0tOdjYMeta || {},
    component: () => import("/app/apps/web/pages/chat/new.vue")
  }
]
  },
  {
    name: "employees",
    path: "/employees",
    meta: index7lgcdDtzGEMeta || {},
    component: () => import("/app/apps/web/pages/employees/index.vue")
  },
  {
    name: "google-connect",
    path: "/google/connect",
    component: () => import("/app/apps/web/pages/google/connect.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/apps/web/pages/index.vue")
  },
  {
    name: "integrations",
    path: "/integrations",
    meta: integrations9QJIMIrkW5Meta || {},
    component: () => import("/app/apps/web/pages/integrations.vue")
  },
  {
    name: "login-confirm",
    path: "/login/confirm",
    meta: indexT3xEHBaZ63Meta || {},
    component: () => import("/app/apps/web/pages/login/confirm/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexQJhoqCw595Meta || {},
    component: () => import("/app/apps/web/pages/login/index.vue")
  },
  {
    name: "manage",
    path: "/manage",
    meta: indexDQga8WyskIMeta || {},
    component: () => import("/app/apps/web/pages/manage/index.vue")
  },
  {
    name: "overview",
    path: "/overview",
    component: () => import("/app/apps/web/pages/overview/index.vue")
  }
]