<script setup lang="ts">
defineOptions({
  name: 'SideMenu',
})

const route = useRoute()
const emit = defineEmits(['close'])

const currentPath = computed(() => route.path)
const nuxtApp = useNuxtApp()

const supabase = useSupabaseClient()
const signoutLoading = ref(false)

async function logout() {
  signoutLoading.value = true
  await supabase.auth.signOut()
  nuxtApp.$trackUserLogOut()
  signoutLoading.value = false
  navigateTo('/login')
}

function handleNavigate(path: string) {
  navigateTo(path)
  emit('close')
}
</script>

<template>
  <div
    class="w-[84px] min-w-[84px] h-dvh bg-gray-900 py-10 px-2 flex flex-col items-center text-gray-300 max-sm:items-start max-sm:p-5 z-30"
  >
    <div
      class="flex items-center max-sm:justify-between sm:justify-center w-full mb-8 max-sm:mb-10"
    >
      <img src="/logo-symbol-white.svg" alt="side menu logo" class="w-[29px]" />
      <Button
        @click="emit('close')"
        variant="ghost"
        class="max-[900px]:z-20 p-0 hover:bg-transparent sm:hidden"
      >
        <LazyIconX class="text-white" />
      </Button>
    </div>
    <div class="flex flex-col items-center gap-5 max-sm:justify-start max-sm:w-full">
      <button
        @click="handleNavigate('/')"
        :class="[
          'max-sm:hover:bg-gray-800 max-sm:rounded-[6px] max-sm:w-full text-center group max-sm:flex max-sm:items-center max-sm:justify-start max-sm:gap-3',
          { 'max-sm:bg-gray-700 max-sm:text-white': currentPath === '/' },
        ]"
      >
        <span
          :class="[
            'mx-auto w-12 h-12 rounded-md flex items-center justify-center text-center group-hover:text-white transition max-sm:w-fit max-sm:mx-3',
            { 'sm:bg-gray-700 sm:text-white': currentPath === '/' },
          ]"
        >
          <LazyIconHome :stroke-width="1.5" class="w-6 h-6" />
        </span>
        <span class="text-sm mt-1 leading-6 min-h-6 inline-block group-hover:text-white transition"
          >Home</span
        >
      </button>
      <button
        @click="handleNavigate('/chat')"
        :class="[
          'max-sm:hover:bg-gray-800 max-sm:rounded-[6px] max-sm:w-full text-center group max-sm:flex max-sm:items-center max-sm:justify-start max-sm:gap-3',
          { 'max-sm:bg-gray-700 max-sm:text-white': currentPath.startsWith('/chat') },
        ]"
      >
        <span
          :class="[
            'mx-auto w-12 h-12 rounded-md flex items-center justify-center text-center group-hover:text-white transition max-sm:w-fit max-sm:mx-3',
            { 'sm:bg-gray-700 sm:text-white': currentPath.startsWith('/chat') },
          ]"
        >
          <LazyIconMessageCircle :stroke-width="1.5" class="w-6 h-6" />
        </span>
        <span class="text-sm mt-1 leading-6 min-h-6 inline-block group-hover:text-white transition"
          >Chat</span
        >
      </button>
      <button
        @click="handleNavigate('/manage')"
        :class="[
          'max-sm:hover:bg-gray-800 max-sm:rounded-[6px] max-sm:w-full text-center group max-sm:flex max-sm:items-center max-sm:justify-start max-sm:gap-3',
          { 'max-sm:bg-gray-700 max-sm:text-white': currentPath.startsWith('/manage') },
        ]"
      >
        <span
          :class="[
            'mx-auto w-12 h-12 rounded-md flex items-center justify-center text-center group-hover:text-white transition max-sm:w-fit max-sm:mx-3',
            { 'sm:bg-gray-700 sm:text-white': currentPath.startsWith('/manage') },
          ]"
        >
          <LazyIconSettings :stroke-width="1.5" class="w-6 h-6" />
        </span>
        <span class="text-sm mt-1 leading-6 min-h-6 inline-block group-hover:text-white transition"
          >Manage</span
        >
      </button>
    </div>
    <button
      @click="logout"
      :disabled="signoutLoading"
      class="text-center mt-auto group max-sm:flex max-sm:items-center max-sm:justify-start"
    >
      <span
        class="mx-auto w-12 h-12 rounded-md flex items-center justify-center text-center group-hover:text-white transition max-sm:w-fit max-sm:mx-3"
      >
        <LazyIconLoader v-if="signoutLoading" :stroke-width="1.5" class="w-6 h-6 animate-spin" />
        <LazyIconLogOut v-else :stroke-width="1.5" class="w-6 h-6" />
      </span>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use './index.scss' as *;
</style>
