import { HybridSearchResponse } from '@/server/api/ai/HybridSearchResponse'
import { useStorage } from '@vueuse/core'

type History = {
  uuid: string
  userId: string
  searchQuery: string
  generativeAiResponse: {
    answer: string
    sources: Array<{
      asset_id: number
      name: string
      datasource_id: string
      datasource_type: string
      asset_mimetype: string
      asset_author: string
      score: number
    }>
    trace_id: string
  }
  searchResponse: HybridSearchResponse
}

export default function useSearchHistoryState() {
  const state = useStorage<History[]>('search-recent-history', [])

  const historyPreview = useState<History | null>(() => null)

  function add(obj: History) {
    const index = state.value.findIndex(o => o.uuid === obj.uuid && o.userId === obj.userId)
    if (index >= 0) return update(obj, index)

    if (state.value.length === 10) {
      state.value.pop()
    }

    state.value.unshift(obj)
  }

  function remove(obj: History) {
    const index = state.value.findIndex(o => o.uuid === obj.uuid && o.userId === obj.userId)
    if (index >= 0) {
      state.value.splice(index, 1)
    }
  }

  function update(obj: History, index: number) {
    state.value[index] = obj
  }

  function preview(uuid: string, userId: string) {
    historyPreview.value = state.value.find(o => o.uuid === uuid && o.userId === userId) || null
  }

  function exitPreview() {
    historyPreview.value = null
  }

  return {
    state,
    add,
    remove,
    preview,
    historyPreview,
    exitPreview,
  }
}
