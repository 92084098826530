import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAlignLeft, LazySvgoBox, LazySvgoDocx, LazySvgoDropbox, LazySvgoErrorIllustration, LazySvgoExcel, LazySvgoGeneralDocument, LazySvgoGoogleDocs, LazySvgoGoogleDrive, LazySvgoGoogleSheets, LazySvgoGoogleSlides, LazySvgoGoogle, LazySvgoHandThumbDown, LazySvgoHandThumbUp, LazySvgoLogo, LazySvgoNoResults, LazySvgoOnedrive, LazySvgoPdf, LazySvgoPptx, LazySvgoSharepoint, LazySvgoTriangle } from '#components'
const lazyGlobalComponents = [
  ["SvgoAlignLeft", LazySvgoAlignLeft],
["SvgoBox", LazySvgoBox],
["SvgoDocx", LazySvgoDocx],
["SvgoDropbox", LazySvgoDropbox],
["SvgoErrorIllustration", LazySvgoErrorIllustration],
["SvgoExcel", LazySvgoExcel],
["SvgoGeneralDocument", LazySvgoGeneralDocument],
["SvgoGoogleDocs", LazySvgoGoogleDocs],
["SvgoGoogleDrive", LazySvgoGoogleDrive],
["SvgoGoogleSheets", LazySvgoGoogleSheets],
["SvgoGoogleSlides", LazySvgoGoogleSlides],
["SvgoGoogle", LazySvgoGoogle],
["SvgoHandThumbDown", LazySvgoHandThumbDown],
["SvgoHandThumbUp", LazySvgoHandThumbUp],
["SvgoLogo", LazySvgoLogo],
["SvgoNoResults", LazySvgoNoResults],
["SvgoOnedrive", LazySvgoOnedrive],
["SvgoPdf", LazySvgoPdf],
["SvgoPptx", LazySvgoPptx],
["SvgoSharepoint", LazySvgoSharepoint],
["SvgoTriangle", LazySvgoTriangle],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
