export default function useUserState() {
  const router = useRouter()
  const route = useRoute()
  const session = useValidatedSupabaseSession()
  const supabaseClient = useSupabaseClient()

  const _initialState = {
    processingStatusLoading: false,
    processingStatusError: null,
    processingStatus: {
      hasSucceededAsset: 'not_inited',
      hasPendingOrInProgressAsset: 'not_inited',
    },
    dataSource: 'not_inited',
    dataSourceLoading: false,
    dataSourceError: null,
  }
  const state = useState<{
    processingStatusLoading: boolean
    processingStatusError: null
    processingStatus: {
      hasSucceededAsset: 'not_inited' | null | boolean
      hasPendingOrInProgressAsset: 'not_inited' | null | boolean
    }
    dataSource:
      | 'not_inited'
      | null
      | {
          id: number
          last_indexed_at: string
          synced_at: string
          assets_size: never
          assets_count: never
          damaged_assets_count: never
        }
    dataSourceLoading: boolean
    dataSourceError: null
  }>('homePageInsights', () => JSON.parse(JSON.stringify(_initialState)))

  function resetState() {
    state.value = JSON.parse(JSON.stringify(_initialState))
  }

  const initInProgress = computed(
    () => state.value.processingStatusLoading || state.value.dataSourceLoading,
  )
  const inited = computed(
    () =>
      state.value.processingStatus.hasSucceededAsset !== 'not_inited' &&
      state.value.processingStatus.hasPendingOrInProgressAsset !== 'not_inited' &&
      state.value.dataSource !== 'not_inited',
  )

  watch(
    () => [state.value.dataSourceError, state.value.processingStatusError],
    () => {
      if (route.fullPath.startsWith('/login')) return
      if (!session.value?.user?.id) return

      if (state.value.dataSourceError) console.error(state.value.dataSourceError)
      if (state.value.processingStatusError) console.error(state.value.processingStatusError)
      showError('Something went wrong while loading the page, refresh the page and try again.')
    },
  )

  async function fetchProcessingStatus() {
    if (
      state.value.processingStatus.hasSucceededAsset !== 'not_inited' ||
      state.value.processingStatus.hasPendingOrInProgressAsset !== 'not_inited'
    )
      return

    state.value.processingStatusLoading = true

    try {
      const res = await $fetch('/api/integrations/homepage-insights')
      state.value.processingStatus.hasSucceededAsset = res.hasSucceededAsset
      state.value.processingStatus.hasPendingOrInProgressAsset = res.hasPendingOrInProgressAsset
    } catch (err) {
      if (err.statusCode === 401) {
        state.value.processingStatusLoading = false
        await supabaseClient.auth.signOut()
        return router.push('/login')
      }

      state.value.processingStatusError = err
    } finally {
      state.value.processingStatusLoading = false
    }
  }

  async function fetchDataSourceDetails() {
    if (state.value.dataSource !== 'not_inited') return

    state.value.dataSourceLoading = true
    try {
      const res = await $fetch('/api/user/data-source')
      state.value.dataSource = res
    } catch (err) {
      if (err.statusCode === 401) {
        state.value.dataSourceLoading = false
        await supabaseClient.auth.signOut()
        return router.push('/login')
      }

      state.value.dataSourceError = err
    } finally {
      state.value.dataSourceLoading = false
    }
  }

  watchEffect(() => {
    if (!session.value?.user?.id) {
      return resetState()
    }

    if (route.fullPath.startsWith('/login')) return
    if (inited.value || initInProgress.value) return
    if (import.meta.server) return

    fetchProcessingStatus()
    fetchDataSourceDetails()
  })

  watchEffect(() => {
    if (
      !state.value.dataSourceLoading &&
      !state.value.dataSourceError &&
      !state.value.dataSource &&
      !route.fullPath.startsWith('/integrations') &&
      !route.fullPath.startsWith('/google')
    ) {
      return router.push('/integrations')
    }
  })

  // watch(() => [state.value.dataSource, route.fullPath], () => {
  // 	const atLeastOneIntegrationIsRequired = state.value.dataSource !== 'not_inited' && !state.value.dataSource && !state.value.dataSourceError
  // 	if (atLeastOneIntegrationIsRequired) {
  // 		router.push('/integrations')
  // 	}
  // })

  return {
    state,
    userStateInited: inited,
    fetchProcessingStatus,
    fetchDataSourceDetails,
  }
}
