<script setup lang="ts">
import { HybridSearchResponse } from '@/server/api/ai/HybridSearchResponse'

defineOptions({ name: 'SearchPage' })
useHead({ title: 'Home' })

const SEARCH_ITEMS_ANIMATION_DELAY_MS = 300

const route = useRoute()
const nuxtApp = useNuxtApp()
const session = useValidatedSupabaseSession()
const { state: userState, userStateInited } = useUserState()
const searchHistoryState = useSearchHistoryState()
const langfuseWeb = useLangfuseWeb()

const viewMode = ref<'search-only' | 'results'>('search-only')
const search = ref('')
const responseTime = ref(0)
// set and launch search if there's query in the url
onMounted(() => {
  if (route.query.q) {
    search.value = route.query.q as string
    runSearch(search.value)
  }
})

const filtersInitState = {
  mimeTypes: [],
  date: {
    start: '',
    end: '',
  },
}
const filters = reactive(JSON.parse(JSON.stringify(filtersInitState)))

const _filters = computed(() => {
  if (!filters.mimeTypes?.length && !filters.date.start && !filters.date.end) return undefined

  return {
    file_types: filters.mimeTypes?.length ? filters.mimeTypes : undefined,
    created_after: filters.date.start || undefined,
    created_before: filters.date.end || undefined,
  }
})

// generative search
const {
  data: generativeSearchData,
  status: generativeSearchStatus,
  error: generativeSearchError,
  execute: generativeSearch,
} = useStreamPostFetch('/api/ai/generative-search', () => ({
  method: 'POST',
  body: {
    prompt: search.value,
    // filters: _filters.value,
    stream: true,
  },
  server: false,
  immediate: false,
  timeout: 60000,
}))

async function handleGenerativeSearchRefresh() {
  if (formattedData.value.trace_id) {
    await langfuseWeb.score({
      traceId: formattedData.value.trace_id,
      name: 'regenerate',
      dataType: 'BOOLEAN',
      value: 0,
      comment: 'User has regenerated the response',
    })
  }

  await generativeSearch()
}

const shouldRunGenerativeSearch = computed(() => search.value.trim().split(' ').length > 2)

type GenerativeJson = {
  answer: string
  sources: Array<{
    asset_id: number
    name: string
    datasource_id: string
    datasource_type: string
    asset_mimetype: string
    asset_author: string
    score: number
  }>
  trace_id: string
}
const _formatedData = ref<GenerativeJson | null>(null)
const formattedData = computed<GenerativeJson | null>({
  get() {
    let response = null

    if (_formatedData.value && !generativeSearchData.value) return _formatedData.value
    if (generativeSearchData.value === null) return null

    const arr = generativeSearchData.value.split('data: ')

    try {
      response = JSON.parse(arr.map(str => str.replaceAll(/\n\n/gm, '')).pop())
    } catch (err) {}

    if (response) _formatedData.value = response

    return response || _formatedData.value
  },
  set(val: GenerativeJson) {
    _formatedData.value = val
  },
})

const sortHybridSearchBy = ref<string | undefined>(undefined)
const paginationInitState = {
  perPage: 5,
  exclude: [],
  noMoreResults: false,
  status: 'idle',
}

let paginationTimeout: ReturnType<typeof setTimeout>
onUnmounted(() => clearTimeout(paginationTimeout))
const pagination = ref(JSON.parse(JSON.stringify(paginationInitState)))

function loadMore() {
  pagination.value.status = 'pending'
  pagination.value.exclude.push(...(hybridData.value.assets || []).map(o => o.asset_id))
  hybridSearch()
}

// hybrid search
const {
  data: hybridData,
  status: hybridStatus,
  error: hybridError,
  execute: hybridSearch,
} = await useAsyncData(
  () =>
    $fetch(`/api/ai/hybrid-search`, {
      method: 'post',
      body: {
        prompt: search.value,
        filters: _filters.value,
        sort_by: sortHybridSearchBy.value,
        // user_id: session.value.user?.id,
        // size: pagination.value.perPage,
        // exclude: pagination.value.exclude,
      },
      timeout: 50000,
    }),
  {
    transform(data): HybridSearchResponse {
      if (data.assets.length < pagination.value.perPage) pagination.value.noMoreResults = true

      function concatPaginationResults() {
        return {
          assets: [...hybridData.value.assets, ...data.assets],
          datasource_counts: {
            google_drive:
              Number(hybridData.value?.datasource_counts?.google_drive || 0) +
              Number(data?.datasource_counts?.google_drive || 0),
          },
        } as HybridSearchResponse
      }

      if (pagination.value.status === 'pending') {
        pagination.value.status = 'idle'

        const arr = concatPaginationResults()
        clearTimeout(paginationTimeout)
        paginationTimeout = setTimeout(() => {
          document
            .querySelector('[data-item="default-layout-parent-el"]')
            .scrollTo({ top: 999999, behavior: 'smooth' })
        }, arr.assets.length * SEARCH_ITEMS_ANIMATION_DELAY_MS)
        return arr
      }

      return data
    },
    server: false,
    immediate: false,
    lazy: true,
  },
)

watch(
  () => _filters.value,
  () => {
    // if (shouldRunGenerativeSearch.value) generativeSearch()
    hybridSearch()
  },
  {
    deep: true,
  },
)

watch(
  () => sortHybridSearchBy.value,
  () => {
    hybridSearch()
  },
)

const showResults = ref(false)

async function runSearch(val: string) {
  search.value = val

  nuxtApp.$trackSearchPerformed(val)
  ;(document as any).activeElement.blur()
  viewMode.value = search.value.trim()?.length ? 'results' : 'search-only'

  searchHistoryState.exitPreview()

  if (viewMode.value === 'search-only') {
    resetSearch()
    return
  }

  pagination.value = JSON.parse(JSON.stringify(paginationInitState))

  //
  navigateTo(`?q=${search.value}`, { replace: true })

  const searchStartTime = performance.now()

  await waitFor(500)
  if (shouldRunGenerativeSearch.value) generativeSearch()
  hybridSearch()

  const searchEndTime = performance.now()
  responseTime.value = searchEndTime - searchStartTime
}

const waitFor = (t: number) => new Promise(resolve => setTimeout(() => resolve(true), t))

watch(
  () => viewMode.value,
  async (newVal, oldVal) => {
    if (oldVal === 'search-only' && newVal === 'results') {
      await waitFor(600)
      showResults.value = true
    }
  },
)

const containerWidth = computed(() => ({
  'max-w-[650px] w-full': viewMode.value === 'search-only',
  'search-container': viewMode.value === 'results',
}))

const searchDisabled = computed(
  () => userState.value.processingStatusLoading || !userStateInited.value,
)
const searchIsNotAllowed = computed(() => !userState.value.processingStatus.hasSucceededAsset)

const generativeSearchIsLoading = computed(
  () =>
    viewMode.value === 'results' &&
    (generativeSearchStatus.value === 'idle' || generativeSearchStatus.value === 'pending'),
)
const hybridSearchIsLoading = computed(
  () =>
    viewMode.value === 'results' &&
    (hybridStatus.value === 'idle' || hybridStatus.value === 'pending'),
)

function resetSearch() {
  hybridData.value = null
  generativeSearchData.value = null
  showResults.value = false
  filters.date.start = ''
  filters.date.end = ''
  filters.mimeTypes = []
  search.value = ''
  searchHistoryState.exitPreview()
  pagination.value = paginationInitState

  navigateTo('', { replace: true })
}

const sortIsDisabled = computed(() => !hybridData.value?.assets?.length)
const filtersAreDisabled = computed(
  () =>
    JSON.stringify(filtersInitState) === JSON.stringify(filters) &&
    !hybridData.value?.assets?.length &&
    !formattedData.value?.sources?.length,
)

watch(
  () => [hybridStatus.value, generativeSearchStatus.value, formattedData.value && hybridData.value],
  () => {
    const readyToInsertNewHistory =
      !searchHistoryState.historyPreview.value &&
      formattedData.value &&
      hybridData.value &&
      hybridStatus.value === 'success' &&
      generativeSearchStatus.value === 'success'

    if (readyToInsertNewHistory) {
      searchHistoryState.add({
        uuid: formattedData.value.trace_id,
        userId: session.value.user?.id,
        searchQuery: search.value,
        generativeAiResponse: formattedData.value,
        searchResponse: hybridData.value,
      })
    }
  },
  {
    deep: true,
  },
)

watch(
  () => formattedData.value,
  () => {
    const readyToUpdateGenerativeSearch =
      searchHistoryState.historyPreview.value &&
      formattedData.value &&
      generativeSearchStatus.value === 'success'
    if (!readyToUpdateGenerativeSearch) return

    const i = searchHistoryState.state.value.findIndex(
      o => o.uuid === searchHistoryState.historyPreview.value.uuid,
    )
    searchHistoryState.state.value[i].generativeAiResponse = formattedData.value
  },
  {
    immediate: false,
    deep: true,
  },
)

watch(
  () => pagination.value.noMoreResults,
  newValue => {
    if (newValue && formattedData.value?.sources && formattedData.value?.answer) {
      const referencesCount = formattedData.value.sources.reduce((count, _, i) => {
        return formattedData.value.answer.includes(`[${i + 1}]`) ? count + 1 : count
      }, 0)

      nuxtApp.$trackSearchAnswerGenerated(
        formattedData.value.answer,
        hybridData.value?.assets?.length,
        formattedData.value.trace_id,
        responseTime.value,
        referencesCount,
      )
    }
  },
  {
    immediate: false,
  },
)
watch(
  () => searchHistoryState.historyPreview.value,
  async () => {
    if (!searchHistoryState.historyPreview.value) return

    search.value = searchHistoryState.historyPreview.value.searchQuery
    navigateTo(`?q=${search.value}`, { replace: true })

    hybridStatus.value = 'idle'
    generativeSearchStatus.value = 'idle'
    viewMode.value = 'results'
    await waitFor(600)
    showResults.value = true
    hybridStatus.value = 'pending'
    generativeSearchStatus.value = 'pending'
    await waitFor(600)

    formattedData.value = searchHistoryState.historyPreview.value.generativeAiResponse
    generativeSearchStatus.value = 'success'
    await waitFor(600)

    hybridData.value = searchHistoryState.historyPreview.value.searchResponse
    hybridStatus.value = 'success'
  },
  {
    deep: true,
  },
)

const showFilters = ref(false)
</script>

<template>
  <div
    class="container flex flex-col p-10 max-[900px]:p-5"
    style="transition: 0.3s"
    :class="{
      'justify-center': viewMode === 'search-only',
      'h-[calc(100dvh-61px)]': viewMode === 'search-only',
    }"
  >
    <div class="flex flex-col mx-auto mb-7" :class="containerWidth" v-auto-animate>
      <div
        v-if="userState.processingStatusLoading || userState.dataSourceLoading || !userStateInited"
        data-testid="searchLoadingSkeleton"
        class="flex flex-col items-center justify-center"
      >
        <Skeleton class="w-2/3 rounded-full" style="height: 39px"></Skeleton>
        <Skeleton class="mt-3 mb-6 w-1/2 rounded-full" style="height: 20px"></Skeleton>
        <Skeleton class="rounded-full w-full" style="height: 50px"></Skeleton>
      </div>
      <template v-else>
        <template v-if="viewMode === 'search-only'">
          <div class="mx-auto text-center mb-[35.91px]">
            <img src="/logo-symbol.svg" alt="logo" class="h-[70px] w-auto" />
          </div>
          <h1 class="text-3xl font-semibold text-center max-[900px]:text-[28px] max-sm:text-2xl">
            How can I assist you today?
          </h1>
          <h2
            class="text-base mt-2 mb-11 text-center text-gray-700 max-[900px]:mb-8 max-[900px]:mt-[7px] max-[900px]:text-sm"
          >
            Search across multiple data sources using AI.
          </h2>
        </template>
        <div class="flex align-middle gap-2">
          <LazySearchInput
            :disabled="searchIsNotAllowed || searchDisabled"
            :search-value="search"
            :view-mode="viewMode"
            @run-search="runSearch"
          ></LazySearchInput>
          <button
            @click="showFilters = !showFilters"
            v-if="viewMode === 'results'"
            class="p-3.5 rounded-full bg-gray-100 min-[900px]:hidden"
          >
            <LazyIconFilter class="text-gray-700"></LazyIconFilter>
          </button>
        </div>

        <LazySearchFilters
          v-if="viewMode === 'results' && showFilters"
          :results-count="hybridData?.assets.length || 0"
          class="min-[900px]:hidden mt-5"
          style="animation-delay: 300ms"
          @date="filters.date = $event"
          @mime-types="filters.mimeTypes = $event"
          :disable-all-filters="filtersAreDisabled"
        />

        <span v-if="searchIsNotAllowed" class="text-center mt-4 text-gray-700 block w-full text-sm">
          Search is unavailable. No files yet or still processing. Please check back soon.
        </span>
        <LazySearchHistory v-if="viewMode === 'search-only'" @search="runSearch($event)" />
      </template>
    </div>

    <!--  -->
    <div v-if="showResults" class="mx-auto" :class="containerWidth">
      <LazySearchFilters
        v-if="viewMode === 'results'"
        :results-count="hybridData?.assets.length || 0"
        class="fadeUp max-[900px]:hidden"
        style="animation-delay: 300ms"
        @date="filters.date = $event"
        @mime-types="filters.mimeTypes = $event"
        :disable-all-filters="filtersAreDisabled"
      />
      <div class="flex gap-8 w-full">
        <div class="flex-grow" style="max-width: calc(1052px - 270px - 32px)">
          <div class="w-full mb-9" v-show="shouldRunGenerativeSearch" v-auto-animate>
            <LazyResponseFailure
              v-if="generativeSearchError && !generativeSearchIsLoading"
              :message="'Something went wrong with the AI search!'"
              :description="`We couldn't process your request. The AI might be taking a break. Try again and let’s see if it gets back on track.`"
              :retry-text="`Retry AI Search`"
              @retry="generativeSearch()"
            >
            </LazyResponseFailure>
            <div v-else>
              <LazySearchGenerativeLoading
                v-if="generativeSearchIsLoading"
                class="mx-auto fadeUp"
                style="animation-delay: 600ms"
              />
              <LazySearchGenerative
                v-if="!generativeSearchIsLoading && Boolean(formattedData?.answer)"
                :content="formattedData"
                @refresh="handleGenerativeSearchRefresh"
                :query="search"
                class="mx-auto"
              />
            </div>
          </div>
          <LazySearchSortBar
            v-if="hybridSearchIsLoading || hybridData || hybridError"
            :skeleton="hybridSearchIsLoading"
            class="mb-6 fadeUp"
            :results-count="hybridData?.assets?.length || 0"
            style="animation-delay: 1000ms"
            :disable-sort="sortIsDisabled"
            @sort-by="sortHybridSearchBy = $event"
          >
          </LazySearchSortBar>

          <div class="fadeUp" style="animation-delay: 1100m">
            <template v-if="pagination.status !== 'pending' && hybridSearchIsLoading">
              <LazySearchItem
                v-for="(n, i) in 3"
                class="w-full mb-6 pb-4 fadeUp"
                :style="{
                  animationDelay: `${sortHybridSearchBy ? i + 1 : (i + 3) * SEARCH_ITEMS_ANIMATION_DELAY_MS}ms`,
                }"
                :skeleton="true"
              />
            </template>
            <template v-else>
              <LazySearchItem
                v-for="(asset, i) in hybridData?.assets ? hybridData.assets : []"
                :data="asset"
                class="w-full mx-auto mb-6 pb-4 fadeUp"
                :style="{ animationDelay: `${(i + 1) * SEARCH_ITEMS_ANIMATION_DELAY_MS}ms` }"
              />
            </template>
            <LazySearchEmptyResults
              v-if="!hybridSearchIsLoading && !hybridError && !hybridData?.assets?.length"
              :search-query="search"
              class="fadeUp"
              style="animation-delay: 100ms"
            />
            <LazyResponseFailure
              v-if="hybridError && !hybridSearchIsLoading"
              :message="`Our search encountered an issue!`"
              :description="`Looks like we’re having trouble combining the results. Give it another shot and we’ll sort it out.`"
              @retry="hybridSearch()"
            >
            </LazyResponseFailure>
            <!-- <div v-if="hybridData?.assets?.length >= pagination.perPage" class="w-full mt-7 text-center mb-20 fadeUp"
							:style="{ animationDelay: `${hybridData?.assets?.length * SEARCH_ITEMS_ANIMATION_DELAY_MS}ms` }">
							<LazyButton @click="loadMore" :disabled="hybridSearchIsLoading"
								class="gap-2 ps-3 pe-4 flex items-center mx-auto bg-gray-50 h-8" variant="outline">
								<LazyIconLoader v-if="hybridSearchIsLoading" class="w-4 h-4 animate-spin" />
								<LazyIconPlus v-else class="w-4 h-4"></LazyIconPlus>
								<span>Load more</span>
							</LazyButton>
						</div> -->
          </div>
        </div>
        <div v-if="viewMode === 'results'" class="min-w-[270px] w-[270px] max-[900px]:hidden">
          <div v-if="hybridSearchIsLoading" class="w-full fadeUp" style="animation-delay: 400ms">
            <LazySkeleton class="w-[150px] h-5 mb-5" />
            <LazySkeleton class="w-full h-9" />
            <LazySkeleton class="w-full h-9 mt-3" />
          </div>
          <div v-else class="w-full">
            <span class="text-sm text-gray-400 mb-5 block">
              Found {{ hybridData?.assets?.length || 0 }} Results
            </span>
            <div class="rounded-xl h-9 flex items-center gap-3 px-3 bg-indigo-50 text-indigo-600">
              <LazyIconSearch class="w-5 h-5" />
              <span class="text-sm font-medium">All</span>
              <span class="text-xs ms-auto">{{ hybridData?.assets?.length || 0 }}</span>
            </div>
            <div class="flex items-center gap-3 mt-3 h-9 px-3">
              <LazyNuxtIcon class="text-xl" name="google-drive" />
              <span class="text-sm text-gray-900 font-medium">Google Drive</span>
              <span class="text-xs ms-auto text-gray-500">{{
                hybridData?.assets?.length || 0
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './index.scss' as *;

.search-container {
  animation: widthChange 0.5s ease forwards;
  max-width: 650px;
  width: 100%;
}

@keyframes widthChange {
  from {
    max-width: 650px;
    width: 100%;
  }

  to {
    max-width: 1052px;
    width: 100%;
  }
}
</style>
