export default defineNuxtRouteMiddleware((to, from) => {
  const session = useValidatedSupabaseSession()

  if (!session.value.user?.user_metadata?.isAdmin) {
    throw createError({
      statusCode: 403,
      statusMessage: `You don't have permission to access this page.`,
    })
  }
})
