import { LangfuseWeb } from 'langfuse'

export function useLangfuseWeb() {
  const config = useRuntimeConfig().public

  return new LangfuseWeb({
    publicKey: config.LANGFUSE_PUBLIC_KEY,
    baseUrl: config.LANGFUSE_BASEURL,
  })
}
