import revive_payload_client_FSHbVoEi41 from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9jqy7V9KoB from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_h1urvVz0tG from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_AC0y6M9SvN from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_5NfBWtWD3D from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2HygpH5LO2 from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UdNtyEcexi from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pteDO33LMA from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_kLhHMDVeQW from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ziYcKwnJdq from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import auth_redirect_Qn7t5ZsAmt from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import posthog_client_fUucxKWhZE from "/app/apps/web/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/app/apps/web/plugins/sentry.client.ts";
export default [
  revive_payload_client_FSHbVoEi41,
  unhead_9jqy7V9KoB,
  router_h1urvVz0tG,
  supabase_client_AC0y6M9SvN,
  payload_client_5NfBWtWD3D,
  navigation_repaint_client_2HygpH5LO2,
  check_outdated_build_client_UdNtyEcexi,
  chunk_reload_client_pteDO33LMA,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kLhHMDVeQW,
  plugin_ziYcKwnJdq,
  auth_redirect_Qn7t5ZsAmt,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]