<script setup lang="ts">
import { cn } from '@/shadcn/utils'

defineOptions({
  name: 'Login',
})

const config = useRuntimeConfig()
const session = useValidatedSupabaseSession()
const nuxtApp = useNuxtApp()

watch(
  session,
  () => {
    if (session.value?.user?.id) {
      return navigateTo('/')
    }
  },
  { immediate: true },
)

const supabase = useSupabaseClient()

const isLoading = ref(false)
async function loginWithGoogle() {
  isLoading.value = true

  try {
    if (config.public.TEST_TYPE === 'e2e') {
      const response = await $fetch('/api/test-e2e-mocks/google-id-token')

      if (!response.credentials.id_token) throw new Error('failed to get the id_token for testing.')

      const { data, error } = await supabase.auth.signInWithIdToken({
        provider: 'google',
        token: response.credentials.id_token,
      })

      if (error) {
        throw error
      }

      return navigateTo('/login/confirm')
    }

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        scopes: config.public.GOOGLE_LOGIN_SCOPES.toString(),
        redirectTo: `${config.public.DOMAIN}/login/confirm`,
        queryParams: {
          access_type: 'offline',
          prompt: 'consent',
        },
      },
    })

    if (error) {
      throw error
    }
    nuxtApp.$trackUserLogIn()
  } catch (error) {
    isLoading.value = false
  }
}

function onSubmit() {}
</script>

<template>
  <div :class="cn('grid gap-6 mt-5', $attrs.class ?? '')">
    <!-- <form @submit="onSubmit">
			<div class="grid gap-2">
				<div class="grid gap-1">
					<Label class="sr-only" for="email">
						Email
					</Label>
					<Input id="email" placeholder="name@example.com" type="email" auto-capitalize="none" auto-complete="email"
						auto-correct="off" :disabled="true" />
				</div>
				<Button :disabled="true">
					<LucideSpinner v-if="isLoading" class="mr-2 h-4 w-4 animate-spin" />
					Sign In with Email
				</Button>
			</div>
		</form>
		<div class="relative">
			<div class="absolute inset-0 flex items-center">
				<span class="w-full border-t" />
			</div>
			<div class="relative flex justify-center text-xs uppercase">
				<span class="bg-background px-2 text-muted-foreground">
					Or continue with
				</span>
			</div>
		</div> -->
    <Button
      @click="loginWithGoogle"
      variant="outline"
      type="button"
      :disabled="isLoading"
      data-testid="loginWithGoogleBtn"
    >
      <LazyIconLoader v-if="isLoading" class="mr-2 h-4 w-4 animate-spin text-black" />
      <NuxtIcon v-else class="mr-2 text-lg mt-1" name="google"></NuxtIcon>
      Google
    </Button>
  </div>
</template>

<style lang="scss" scoped>
@use './index.scss' as *;
</style>
