import posthog from 'posthog-js'

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig()
  const posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
    api_host: runtimeConfig.public.posthogHost,
    person_profiles: 'identified_only',
    loaded: posthog => {
      if (import.meta.dev) posthog.debug()
    },
  })

  const router = useRouter()
  const user = useSupabaseUser()

  // Watch user authentication state
  watch(
    user,
    currentUser => {
      if (currentUser) {
        posthog.identify(currentUser.id, {
          email: currentUser.email,
          name: currentUser.user_metadata?.name || '',
          ...currentUser.user_metadata,
        })
      } else {
        posthog.reset()
      }
    },
    { immediate: true },
  )

  router.afterEach(to => {
    nextTick(() => {
      posthog.capture('$pageview', {
        current_url: to.fullPath,
      })
    })
  })

  const trackUserLogIn = () => {
    posthog.capture('user_logged_in')
  }

  const trackUserLogOut = () => {
    posthog.capture('user_logged_out')
  }

  // Search Interactions
  const trackSearchPerformed = (queryText: string) => {
    posthog.capture('search_performed', {
      query_text: queryText,
    })
  }

  const trackSearchAnswerGenerated = (
    responseText: string,
    resultsCount: number,
    traceId: string,
    responseTimeMs: number,
    sourcesCitedCount: number,
  ) => {
    posthog.capture('search_answer_generated', {
      ai_assistant_text: responseText,
      results_count: resultsCount,
      trace_id: traceId,
      response_time_ms: responseTimeMs,
      sources_cited_count: sourcesCitedCount,
    })
  }

  const trackSearchFeedback = (
    feedbackType: 'copy' | 'regenerate' | 'like' | 'dislike',
    feedbackText: string,
    traceId: string,
    feedbackScore: -1 | 1 | -0.5 | 0.5,
  ) => {
    posthog.capture('search_feedback_submitted', {
      feedback_type: feedbackType,
      feedback_text: feedbackText,
      trace_id: traceId,
      feedback_score: feedbackScore,
    })
  }

  const trackSearchResultClicked = (
    clickedItem: number,
    source: string,
    resultPosition: number,
  ) => {
    posthog.capture('search_result_clicked', {
      clicked_item: clickedItem,
      source: source,
      result_position: resultPosition,
    })
  }

  // Chat Interactions
  const trackChatNew = (chatId: string) => {
    posthog.capture('chat_new', {
      chat_id: chatId,
    })
  }

  const trackChatAnswerGenerated = (
    responseText: string,
    messageIndex: number,
    traceId: string,
    sourcesCitedCount: number,
  ) => {
    posthog.capture('chat_answer_generated', {
      chat_ai_assistant_text: responseText,
      chat_message_index: messageIndex,
      trace_id: traceId,
      sources_cited_count: sourcesCitedCount,
    })
  }

  const trackChatFeedback = (
    feedbackType: 'copy' | 'regenerate' | 'like' | 'dislike',
    feedbackText: string,
    traceId: string,
    feedbackScore: -1 | 1 | -0.5 | 0.5,
  ) => {
    posthog.capture('chat_feedback_submitted', {
      feedback_type: feedbackType,
      feedback_text: feedbackText,
      trace_id: traceId,
      feedback_score: feedbackScore,
    })
  }

  // Source Management
  const trackSourceConnected = (sourceType: string, connectionStatus: 'successful' | 'failed') => {
    posthog.capture('source_connected', {
      source_type: sourceType,
      connection_status: connectionStatus,
    })
  }

  const trackSourceDisconnected = (
    sourceType: string,
    disconnectionReason: 'user_initiated' | 'error' | 'other',
  ) => {
    posthog.capture('source_disconnected', {
      source_type: sourceType,
      disconnection_reason: disconnectionReason,
    })
  }

  return {
    provide: {
      posthog: () => posthogClient,
      trackUserLogIn,
      trackUserLogOut,
      trackSearchPerformed,
      trackSearchAnswerGenerated,
      trackSearchFeedback,
      trackSearchResultClicked,
      trackChatNew,
      trackChatAnswerGenerated,
      trackChatFeedback,
      trackSourceConnected,
      trackSourceDisconnected,
    },
  }
})
