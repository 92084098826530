import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.15.3_@types+node@22.10.10_ioredis@5.4.2_sass-embedded@1.83.0_supports-color@9.4.0_tsx@_4dbm3ack5v3znk6h4etycd67tu/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/apps/web/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}